// @flow
import * as yup from 'yup'

import { regex } from 'utils'

const initialValues = {
  locationIds: [],
  idNumber: '',
  name: '',
  userName: '',
  phoneNumber: '',
  email: '',
  password: '',
  userTypeId: '',
  userStatusId: '',
  idFrontPhoto: null,
  idBackPhoto: null
}

const schema = yup.object().shape({
  locationIds: yup
    .array()
    .of(yup.number().required('La ubicación es requerida')),
  idNumber: yup
    .string()
    .required('La cedúla es obligatoria.')
    .matches(regex.onlyNumbers, 'La cédula solo debe contener numeros.')
    .min(9, 'El mínimo de numeros para la cedúla son 9.'),
  name: yup
    .string()
    .required('El nombre es obligatorio.')
    .matches(regex.onlyLetters, 'El nombre solo puede llevar letras.'),
  userName: yup
    .string()
    .required('El nombre es obligatorio.')
    .min(4, 'El largo minimo es de 4'),
  phoneNumber: yup
    .string()
    .required('El teléfono es obligatorio.')
    .matches(regex.onlyNumbers, 'El teléfono solo debe contener numeros.')
    .min(8, 'El mínimo de numeros para el teléfono son 8.'),
  email: yup.string().email('Este no es un correo valido'),
  password: yup
    .string()
    .required('La contraseña es obligatoria')
    .min(6, 'El largo minimo es de 6'),
  userTypeId: yup.number().required('El tipo de usuario es obligatorio'),
  userStatusId: yup.number().required('El estado del usuario es obligatorio'),
  idFrontPhoto: yup.string().nullable(),
  idBackPhoto: yup.string().nullable()
})

export default {
  initialValues,
  schema
}
