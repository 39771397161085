import React from 'react'
import { Skeleton } from '@material-ui/lab'
import {
  Box,
  Tooltip,
  IconButton,
  CircularProgress,
  Typography,
  Link as MuiLink
} from '@material-ui/core'
import GoogleMapReact from 'google-map-react'
import { MyLocation as MyLocationIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import { MapMarker } from 'components'
import { mapConfig } from 'config'
import { useSizes } from 'hooks'

const DeliveryMap = ({
  loadingCurrentLocation,
  showActions = false,
  defaultCenter,
  center,
  onClick,
  showCurrentLocation,
  isEditable = true
}: {
  loadingCurrentLocation?: boolean,
  showActions?: boolean,
  defaultCenter?: Object,
  center?: Object,
  onClick?: Function,
  showCurrentLocation?: boolean,
  isEditable?: boolean
}) => {
  const { smDown } = useSizes()
  const classes = useStyles()

  return defaultCenter ? (
    <>
      <Box position='relative' height={smDown ? '40vh' : '30vw'} width='100%'>
        <Box
          position='absolute'
          left={10}
          top={10}
          zIndex={1}
          bgcolor='common.white'
        >
          <Tooltip title='Mostrar ubicación'>
            <span>
              <IconButton
                onClick={isEditable ? showCurrentLocation : undefined}
                disabled={loadingCurrentLocation || !isEditable}
              >
                {loadingCurrentLocation ? (
                  <CircularProgress className={classes.icon} size={20} />
                ) : (
                  <MyLocationIcon className={classes.icon} fontSize='small' />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </Box>

        <GoogleMapReact
          bootstrapURLKeys={{ key: mapConfig.apiKey }}
          defaultCenter={defaultCenter}
          center={center}
          defaultZoom={mapConfig.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          onClick={isEditable ? onClick : undefined}
          draggable={isEditable}
        >
          {center ? <MapMarker lat={center.lat} lng={center.lng} /> : null}
        </GoogleMapReact>
      </Box>

      {showActions ? (
        <Box display='flex' flexDirection='row' mt={2}>
          <Box display='flex' flex={1} mr={1}>
            <MuiLink
              target='_blank'
              rel='noreferrer'
              href={`https://waze.com/ul?ll=${center?.lat},${center?.lng}&navigate=yes`}
              style={{ width: '100%' }}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                bgcolor='primary.main'
                color='white'
                py={1}
              >
                <Typography variant='body2'>Abrir en waze</Typography>
              </Box>
            </MuiLink>
          </Box>

          <Box display='flex' flex={1} ml={1}>
            <MuiLink
              target='_blank'
              rel='noreferrer'
              href={`https://www.google.com/maps?saddr=My+Location&daddr=${center?.lat},${center?.lng}`}
              style={{ width: '100%' }}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                bgcolor='primary.main'
                color='white'
                py={1}
              >
                <Typography variant='body2'>Abrir en google maps</Typography>
              </Box>
            </MuiLink>
          </Box>
        </Box>
      ) : null}
    </>
  ) : (
    <Skeleton
      variant='rect'
      height={smDown ? '70vh' : '30vw'}
      width={smDown ? '100%' : '30vw'}
    />
  )
}

const useStyles = makeStyles((theme: Object) => ({
  icon: {
    color: theme.palette.grey[700]
  }
}))

export default DeliveryMap
