// @flow
import * as yup from 'yup'
import moment from 'moment'

import { regex } from 'utils'

const initialValues = {
  locationId: '',
  amount: '',
  penaltyFee: '',
  payday: moment(),
  interestPercentage: '',
  capitalPercentage: '',
  loanPeriodicityId: '',
  loanTypeId: '',
  clientId: '',
  userId: '',
  lastPayday: moment()
}

const schema = yup.object().shape({
  locationId: yup.number().required('La ubicación es obligatorio'),
  amount: yup
    .string()
    .required('El monto es obligatorio.')
    .matches(regex.onlyNumbers, 'El monto solo debe contener numeros.'),
  loanPeriodicityId: yup.number().required('La periodicidad es obligatorio'),
  interestPercentage: yup
    .number()
    .required('El porcentaje del préstamo es obligatorio'),
  capitalPercentage: yup.number().when(['loanTypeId'], {
    is: (val: number) => val === 1,
    then: yup.number(),
    otherwise: yup.number().required('El porcentaje al capital es obligatorio')
  }),
  payday: yup.date().required('La fecha del proximo pago es obligatorio.'),
  penaltyFee: yup
    .string()
    .matches(
      regex.onlyNumbers,
      'El monto de penalizacion solo debe contener numeros.'
    ),
  loanTypeId: yup.number().required('El tipo es obligatorio'),
  clientId: yup.number().required('El cliente es obligatorio'),
  userId: yup.number().required('El cobrador es obligatorio')
})

export default {
  initialValues,
  schema
}
