// @flow
import React from 'react'
import { Switch } from 'react-router-dom'

import Add from './Add'
import List from './List'
import Details from './Details'
import NoMatch from 'screens/NoMatch'
import { CustomRoute } from 'components'

function ClientsRouter() {
  return (
    <Switch>
      <CustomRoute
        exact
        path='/inicio/ubicaciones/agregar'
        component={Add}
        isPrivate
        isProtected
      />
      <CustomRoute
        exact
        path='/inicio/ubicaciones/mostrar'
        component={List}
        isPrivate
        isProtected
      />

      <CustomRoute
        exact
        path='/inicio/ubicaciones/detalle/:id'
        component={Details}
        isPrivate
        isProtected
      />

      <CustomRoute component={NoMatch} />
    </Switch>
  )
}

export default ClientsRouter
