// @flow
import React, { useState, useEffect } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Box,
  Typography,
  Grid
} from '@material-ui/core'
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon
} from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import { Formik, Form, Field } from 'formik'
import { useParams } from 'react-router-dom'
import useSWR, { useSWRConfig } from 'swr'

import { TextField, Button, Snackbar, Map } from 'components'
import { locationScheme } from 'schemas'
import { usePost } from 'hooks'
import { formatDate } from 'utils'
import { mapConfig } from 'config'

const { initialValues, schema } = locationScheme

function Detail() {
  const { id } = useParams()
  const [isEditable, setIsEditable] = useState(false)
  const { cache } = useSWRConfig()
  const cachedLocation = cache
    ?.get('location/all')
    ?.find((location: Object) => Number(location?.id) === Number(id))
  const { data, error: getError, mutate } = useSWR(`location/${id}`, {
    fallbackData: cachedLocation
  })
  const getLoading = !data && !getError
  const {
    post,
    isError: postIsError,
    loading: postLoading,
    error: postError
  } = usePost()
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(false)
  const [geolocation, setGeolocation] = useState(null)
  const geolocationLocation = navigator?.geolocation
  const geography = geolocation
    ? {
        type: 'Point',
        coordinates: [geolocation.lng, geolocation.lat]
      }
    : null

  const onMapClick = (value: Object) => {
    setGeolocation({
      lat: value.lat,
      lng: value.lng
    })
  }

  const handleShowCurrentLocation = () => {
    if (geolocationLocation) {
      setLoadingCurrentLocation(true)
      geolocationLocation.getCurrentPosition((position: Object) => {
        const { latitude: lat, longitude: lng } = position.coords
        setGeolocation({
          lat,
          lng
        })
        setLoadingCurrentLocation(false)
      })
    }
  }

  useEffect(() => {
    if (!data) {
      return
    }

    const geolocation = data?.geolocation

    if (geolocation) {
      setGeolocation({
        lng: geolocation?.coordinates?.[0],
        lat: geolocation?.coordinates?.[1]
      })
    }
  }, [data])

  if (getLoading) {
    return (
      <Card>
        <CardHeader title={<Skeleton height={50} width='100%' />} />
        <CardContent>
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
        </CardContent>
      </Card>
    )
  }

  if (getError) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {getError}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Snackbar
        isVisible={Boolean(postIsError && postError)}
        type='error'
        message={
          typeof postError === 'string'
            ? postError
            : postError && postError.message
        }
      />

      <Formik
        initialValues={{ ...initialValues, ...data }}
        validationSchema={schema}
        onSubmit={(values: Object) =>
          post(
            'location/update',
            {
              ...values,
              geolocation: geography
            },
            () => {
              mutate()
              setIsEditable(false)
            }
          )
        }
      >
        {({
          errors,
          touched,
          resetForm,
          values
        }: {
          errors: Object,
          touched: Object,
          resetForm: Function,
          values: Object
        }) => {
          return (
            <Form>
              <Card>
                <CardHeader
                  title='Ubicación'
                  action={
                    isEditable ? (
                      <Button
                        type='button'
                        fullWidth={false}
                        disabled={postLoading}
                        onClick={() => {
                          resetForm({ ...initialValues, ...data })
                          setIsEditable(false)
                        }}
                        startIcon={<CloseIcon />}
                      >
                        <span>Cerrar</span>
                      </Button>
                    ) : (
                      <Button
                        type='button'
                        fullWidth={false}
                        onClick={() => setIsEditable(true)}
                        startIcon={<EditIcon />}
                      >
                        Editar
                      </Button>
                    )
                  }
                />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='id'
                        as={TextField}
                        label='Id'
                        readOnly
                        disabled={isEditable}
                        error={Boolean(touched.id && errors.id)}
                        helperText={touched.id && errors.id}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='idNumber'
                        as={TextField}
                        label='Número de cédula'
                        readOnly={!isEditable}
                        error={Boolean(touched.idNumber && errors.idNumber)}
                        helperText={touched.idNumber && errors.idNumber}
                      />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <Field
                        name='name'
                        as={TextField}
                        label='Nombre'
                        readOnly={!isEditable}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='phoneNumber'
                        as={TextField}
                        readOnly={!isEditable}
                        label='Número de teléfono'
                        type='tel'
                        error={Boolean(
                          touched.phoneNumber && errors.phoneNumber
                        )}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                      />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <Field
                        name='email'
                        as={TextField}
                        readOnly={!isEditable}
                        label='Correo'
                        type='email'
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='description'
                        as={TextField}
                        readOnly={!isEditable}
                        label='Descripción'
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        helperText={touched.description && errors.description}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='createdAt'
                        as={TextField}
                        label='Fecha de creación'
                        value={formatDate(values.createdAt)}
                        readOnly
                        disabled={isEditable}
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <Map
                        loadingCurrentLocation={loadingCurrentLocation}
                        defaultCenter={mapConfig.center}
                        onClick={onMapClick}
                        center={geolocation}
                        showCurrentLocation={handleShowCurrentLocation}
                        isEditable={isEditable}
                        showActions={!!geolocation}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                {isEditable && (
                  <CardActions>
                    <Button
                      disabled={postLoading}
                      fullWidth={false}
                      startIcon={<SaveIcon />}
                    >
                      <span>Guardar</span>
                    </Button>
                  </CardActions>
                )}
              </Card>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default Detail
