// @flow
import { useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'

import { auth } from 'utils'

export const loanRoutes = [
  'loan/latePayment',
  'loan/todayPayment',
  'loan/all',
  'loan/latePayment/{userId}',
  'loan/todayPayment/{userId}',
  'loan/all/{userId}'
]

const useCachedData = (id: string, routes: Array<Object>) => {
  const [cachedLoan, setCachedLoan] = useState()
  const { cache } = useSWRConfig()
  const { id: userId } = auth.decodedToken()

  useEffect(() => {
    for (const route of routes) {
      const includeUserId = route?.includes('{userId}')
      const routeName = includeUserId
        ? route?.replace('{userId}', userId)
        : route
      const cachedValue = cache
        ?.get(routeName)
        ?.find((loan: Object) => Number(loan?.id) === Number(id))

      if (cachedValue) {
        setCachedLoan(cachedValue)
        break
      }
    }
  }, [cache, userId, id, routes])

  return cachedLoan
}

export default useCachedData
