// @flow
import React from 'react'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { SWRConfig } from 'swr'

import 'config/moment'
import { theme, alertConfig } from 'config'
import { fetch } from 'api'

import App from 'screens'

function Root() {
  if (alertConfig.alertMessage) {
    alert(alertConfig.alertMessage)
  }

  return (
    <SWRConfig
      value={{
        fetcher: fetch.get
      }}
    >
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <App />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </SWRConfig>
  )
}

export default Root
