import React, { useState } from 'react'
import {
  Box,
  Typography,
  CircularProgress,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  FormControl,
  FormHelperText
} from '@material-ui/core'
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon
} from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, Field } from 'formik'
import { DatePicker } from '@material-ui/pickers'
import { useParams } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import useSWR from 'swr'

import { loanScheme } from 'schemas'
import {
  Button,
  AmountField,
  TextField,
  Snackbar,
  FilePreview,
  FileUpload,
  Map
} from 'components'
import { formatDate, auth } from 'utils'
import { constants, mapConfig } from 'config'
import { usePost, useFileUpload, useCachedData } from 'hooks'
import { loanRoutes } from 'hooks/useCachedData'
import { fetch } from 'api'

const { initialValues, schema } = loanScheme.update

function Details() {
  const classes = useStyles()
  const [isEditable, setIsEditable] = useState(false)
  const { id } = useParams()
  const isAdmin = auth.isAdmin()
  const cachedLoan = useCachedData(id, loanRoutes)
  const { data, error: loanError } = useSWR(`loan/${id}`, {
    fallbackData: cachedLoan
  })
  const loanLoading = !data && !loanError
  const { data: loanPropsData, error: loanPropsError, mutate } = useSWR(
    'loan/props'
  )
  const loanPropsLoading = !loanPropsData && !loanPropsError

  const {
    post,
    isError: postIsError,
    loading: postLoading,
    error: postError
  } = usePost()

  const { upload, loading: uploadLoading, error: uploadError } = useFileUpload()

  const geolocation = data?.client?.geolocation
    ? {
        lng: data?.client?.geolocation?.coordinates?.[0],
        lat: data?.client?.geolocation?.coordinates?.[1]
      }
    : undefined

  if (loanLoading) {
    return (
      <Card>
        <CardHeader title={<Skeleton height={50} width='100%' />} />
        <CardContent>
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
        </CardContent>
      </Card>
    )
  }

  if (loanPropsError || loanError) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {loanPropsError || loanError}
        </Typography>
      </Box>
    )
  }

  if (!data) {
    return (
      <Card>
        <CardHeader title={`No existe un préstamo con el id ${id}`} />
      </Card>
    )
  }

  return (
    <>
      <Snackbar
        isVisible={Boolean(postIsError && postError)}
        type='error'
        message={
          typeof postError === 'string'
            ? postError
            : postError && postError.message
        }
      />

      <Snackbar
        isVisible={Boolean(uploadError)}
        type='error'
        message={
          typeof uploadError === 'string'
            ? uploadError
            : uploadError && uploadError.message
        }
      />

      <Formik
        initialValues={{
          ...initialValues,
          ...data
        }}
        validationSchema={schema}
        onSubmit={async (values: Object) => {
          post('loan/update', values, () => {
            mutate()
            setIsEditable(false)
          })
        }}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          resetForm,
          values,
          submitCount
        }: {
          errors: Object,
          touched: Object,
          setFieldValue: Function,
          setFieldTouched: Function,
          resetForm: Function,
          values: Object,
          submitCount: number
        }) => {
          return (
            <Form>
              <Card>
                <CardHeader
                  title='Préstamo'
                  action={
                    !isAdmin ? null : isEditable ? (
                      <Button
                        type='button'
                        fullWidth={false}
                        disabled={postLoading}
                        onClick={() => {
                          resetForm({ ...initialValues, ...data })
                          setIsEditable(false)
                        }}
                      >
                        <CloseIcon className={classes.icon} />
                        Cerrar
                      </Button>
                    ) : (
                      <Button
                        type='button'
                        fullWidth={false}
                        onClick={() => setIsEditable(true)}
                      >
                        <EditIcon className={classes.icon} />
                        Editar
                      </Button>
                    )
                  }
                />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Field name='locationId'>
                        {({ field: { name, value } }: { field: Object }) => {
                          if (loanPropsLoading) {
                            return (
                              <Box display='flex' justifyContent='center'>
                                <CircularProgress />
                              </Box>
                            )
                          }

                          return (
                            <TextField
                              select
                              label='Ubicación'
                              readOnly={!isEditable}
                              onChange={(event: Object) => {
                                setFieldValue(name, event?.target?.value)
                                setFieldValue('userId', '')
                              }}
                              value={value}
                            >
                              {loanPropsData?.locations?.map(
                                (location: Object) => {
                                  return (
                                    <MenuItem
                                      key={location?.id}
                                      value={location?.id}
                                    >
                                      {location?.name}
                                    </MenuItem>
                                  )
                                }
                              )}
                            </TextField>
                          )
                        }}
                      </Field>
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <Field
                        name='id'
                        as={TextField}
                        label='Id'
                        readOnly
                        disabled={isEditable}
                        error={Boolean(touched.id && errors.id)}
                        helperText={touched.id && errors.id}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='client'
                        as={TextField}
                        label='Cliente'
                        value={values.client.name}
                        readOnly
                        disabled={isEditable}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='phoneNumber'
                        as={TextField}
                        label='Número de télefono'
                        value={values.client.phoneNumber}
                        readOnly
                        disabled={isEditable}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='address'
                        as={TextField}
                        label='Dirección'
                        value={values.client.address}
                        readOnly
                        disabled={isEditable}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='createdAt'
                        as={TextField}
                        label='Fecha de creación'
                        value={formatDate(values.createdAt)}
                        readOnly
                        disabled={isEditable}
                      />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <Field name='userId'>
                        {({ field: { name } }: { field: Object }) => (
                          <FormControl fullWidth margin='normal'>
                            <AsyncSelect
                              styles={{
                                container: () => ({
                                  zIndex: 998
                                })
                              }}
                              name={name}
                              isDisabled={!isEditable}
                              placeholder={
                                (values.user && values.user.name) ||
                                'Buscar cobrador...'
                              }
                              noOptionsMessage={() =>
                                'No se encontro ningun resultado.'
                              }
                              loadingMessage={() => 'Buscando...'}
                              getOptionLabel={(option: Object) =>
                                `${option.name} - ${option.idNumber}`
                              }
                              getOptionValue={(option: Object) => option.id}
                              loadOptions={(inputValue: string) =>
                                fetch.get(
                                  `user/nameOrIdNumber/${values?.locationId}/${inputValue}`
                                )
                              }
                              onChange={(user: Object) => {
                                setFieldValue('user', user)
                                setFieldValue(name, user.id)
                              }}
                              onBlur={() => {
                                setFieldTouched(name, true)
                              }}
                            />
                            {Boolean(
                              touched.userId && errors.userId && submitCount
                            ) && (
                              <FormHelperText error>
                                {errors.userId}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <Field name='loanTypeId'>
                        {({ field: { name, value } }: { field: Object }) => {
                          if (loanPropsLoading) {
                            return (
                              <Box display='flex' justifyContent='center'>
                                <CircularProgress />
                              </Box>
                            )
                          }

                          return (
                            <TextField
                              select
                              readOnly={!isEditable}
                              label='Tipo*'
                              value={value}
                              onChange={(event: Object) => {
                                const { value } = event.target
                                const loanTypeSelected = loanPropsData.loanTypes.find(
                                  (type: Object) => type.id === value
                                )

                                setFieldValue(name, value)
                                setFieldValue('loanType', loanTypeSelected)

                                if (loanTypeSelected.type === constants.FIXED) {
                                  setFieldValue('capitalPercentage', '')
                                }
                              }}
                              error={Boolean(
                                touched.loanTypeId && errors.loanTypeId
                              )}
                              helperText={
                                touched.loanTypeId && errors.loanTypeId
                              }
                            >
                              {loanPropsData?.loanTypes?.map(
                                ({
                                  id,
                                  name
                                }: {
                                  id: number,
                                  name: string
                                }) => (
                                  <MenuItem key={id} value={id}>
                                    {name}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          )
                        }}
                      </Field>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field name='amount'>
                        {({ field: { name, value } }: { field: Object }) => (
                          <AmountField
                            name={name}
                            label='Monto*'
                            value={value}
                            readOnly={!isEditable}
                            onValueChange={(values: Object) => {
                              setFieldValue(name, values.value)
                            }}
                            error={Boolean(touched.amount && errors.amount)}
                            helperText={touched.amount && errors.amount}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field name='balance'>
                        {({ field: { name, value } }: { field: Object }) => (
                          <AmountField
                            name={name}
                            label='Balance*'
                            value={value}
                            readOnly={!isEditable}
                            onValueChange={(values: Object) => {
                              setFieldValue(name, values.value)
                            }}
                            error={Boolean(touched.balance && errors.balance)}
                            helperText={touched.balance && errors.balance}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field name='interestPercentage'>
                        {({ field: { name, value } }: { field: Object }) => {
                          if (loanPropsLoading) {
                            return (
                              <Box display='flex' justifyContent='center'>
                                <CircularProgress />
                              </Box>
                            )
                          }

                          return (
                            <TextField
                              select
                              readOnly={!isEditable}
                              value={value}
                              onChange={(event: Object) => {
                                const { value } = event.target

                                setFieldValue(name, value)
                              }}
                              label='Porcentaje al intereses*'
                              error={Boolean(
                                touched.interestPercentage &&
                                  errors.interestPercentage
                              )}
                              helperText={
                                touched.interestPercentage &&
                                errors.interestPercentage
                              }
                            >
                              {loanPropsData?.loanInterestPercentages?.map(
                                (percentage: number) => (
                                  <MenuItem key={percentage} value={percentage}>
                                    {`${percentage}%`}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          )
                        }}
                      </Field>
                    </Grid>

                    <Field name='capitalPercentage'>
                      {({ field: { name, value } }: { field: Object }) => {
                        if (loanPropsLoading) {
                          return (
                            <Box display='flex' justifyContent='center'>
                              <CircularProgress />
                            </Box>
                          )
                        }

                        if (
                          values.loanType &&
                          values.loanType.type === constants.FIXED
                        ) {
                          return null
                        }

                        return (
                          <Grid item sm={4} xs={12}>
                            <TextField
                              select
                              readOnly={!isEditable}
                              value={value}
                              onChange={(event: Object) => {
                                const { value } = event.target
                                setFieldValue(name, value)
                              }}
                              label='Porcentaje al capital*'
                              error={Boolean(
                                touched.capitalPercentage &&
                                  errors.capitalPercentage
                              )}
                              helperText={
                                touched.capitalPercentage &&
                                errors.capitalPercentage
                              }
                            >
                              {loanPropsData?.loanCapitalPercentages?.map(
                                (percentage: number) => (
                                  <MenuItem key={percentage} value={percentage}>
                                    {`${percentage}%`}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </Grid>
                        )
                      }}
                    </Field>

                    <Field name='loanPeriodicityId'>
                      {({ field: { name, value } }: { field: Object }) => {
                        if (loanPropsLoading) {
                          return (
                            <Box display='flex' justifyContent='center'>
                              <CircularProgress />
                            </Box>
                          )
                        }

                        return (
                          <Grid item sm={4} xs={12}>
                            <TextField
                              select
                              readOnly={!isEditable}
                              value={value}
                              onChange={(event: Object) => {
                                const { value } = event.target
                                setFieldValue(name, value)
                              }}
                              label='Periodicidad de pago*'
                              error={Boolean(
                                touched.loanPeriodicityId &&
                                  errors.loanPeriodicityId
                              )}
                              helperText={
                                touched.loanPeriodicityId &&
                                errors.loanPeriodicityId
                              }
                            >
                              {loanPropsData?.loanPeriodicities?.map(
                                ({
                                  id,
                                  name
                                }: {
                                  id: number,
                                  name: string
                                }) => (
                                  <MenuItem key={id} value={id}>
                                    {name}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </Grid>
                        )
                      }}
                    </Field>

                    <Grid item sm={4} xs={12}>
                      <Field name='payday'>
                        {({ field: { value } }: { field: Object }) => {
                          return (
                            <DatePicker
                              margin='normal'
                              inputVariant='outlined'
                              fullWidth
                              label='Fecha del proximo pago'
                              format='DD/MM/YYYY'
                              value={value}
                              onChange={(date: Date) => {
                                setFieldValue('payday', date)
                              }}
                              readOnly={!isEditable}
                            />
                          )
                        }}
                      </Field>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field name='penaltyFee'>
                        {({ field: { name, value } }: { field: Object }) => {
                          return (
                            <AmountField
                              name={name}
                              label='Monto de penalización'
                              value={value}
                              readOnly={!isEditable}
                              onValueChange={(values: Object) => {
                                setFieldValue(name, values.value)
                              }}
                              error={Boolean(
                                touched.penaltyFee && errors.penaltyFee
                              )}
                              helperText={
                                touched.penaltyFee && errors.penaltyFee
                              }
                            />
                          )
                        }}
                      </Field>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field name='loanStatusId'>
                        {({ field: { name, value } }: { field: Object }) => {
                          if (loanPropsLoading) {
                            return (
                              <Box display='flex' justifyContent='center'>
                                <CircularProgress />
                              </Box>
                            )
                          }

                          return (
                            <TextField
                              select
                              readOnly={!isEditable}
                              value={value}
                              onChange={(event: Object) => {
                                const { value } = event.target
                                setFieldValue(name, value)
                              }}
                              label='Estado*'
                              error={Boolean(
                                touched.loanStatusId && errors.loanStatusId
                              )}
                              helperText={
                                touched.loanStatusId && errors.loanStatusId
                              }
                            >
                              {loanPropsData?.loanStatuses?.map(
                                ({
                                  id,
                                  name
                                }: {
                                  id: number,
                                  name: string
                                }) => (
                                  <MenuItem key={id} value={id}>
                                    {name}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          )
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name='contractPhoto'>
                        {({
                          field: { name, value },
                          form: { setFieldValue }
                        }: {
                          field: Object,
                          form: Object
                        }) => {
                          if (!value && !isEditable) {
                            return null
                          }

                          if (!isEditable) {
                            return <FilePreview value={value} />
                          }

                          return (
                            <FileUpload
                              name={name}
                              label='Foto del contrato'
                              loading={uploadLoading}
                              value={value}
                              onChange={async ({
                                target: {
                                  validity,
                                  files: [file]
                                }
                              }: {
                                target: Object
                              }) => {
                                try {
                                  if (validity.valid && file) {
                                    setFieldValue('contractPhoto', '')

                                    const { name, type } = file
                                    const newName = `${Date.now().toString()}${name}`

                                    const fileCopy = new File([file], newName, {
                                      type
                                    })

                                    await upload({
                                      name: fileCopy.name,
                                      type: fileCopy.type,
                                      file: fileCopy,
                                      throwError: true
                                    })

                                    setFieldValue('contractPhoto', newName)
                                  }
                                } catch (error) {
                                  setFieldValue('contractPhoto', '')
                                }
                              }}
                            />
                          )
                        }}
                      </Field>
                    </Grid>
                    {geolocation ? (
                      <Grid item sm={6} xs={12}>
                        <Map
                          defaultCenter={mapConfig.center}
                          center={geolocation}
                          isEditable={false}
                          showActions={true}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </CardContent>
                {isAdmin && isEditable && (
                  <CardActions>
                    <Button disabled={postLoading} fullWidth={false}>
                      <SaveIcon className={classes.icon} />
                      Guardar
                    </Button>
                  </CardActions>
                )}
              </Card>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const useStyles = makeStyles((theme: Object) => ({
  icon: {
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row'
  }
}))

export default Details
