// @flow
import React, { useState } from 'react'
import {
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid
} from '@material-ui/core'
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, Field } from 'formik'
import useSWR from 'swr'

import { Button, Snackbar, TextField } from 'components'
import { userScheme } from 'schemas'
import { usePost } from 'hooks'
import { formatDate } from 'utils'

const { schema } = userScheme.update

function Profile() {
  const classes = useStyles()
  const [isEditable, setIsEditable] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { data, error, mutate } = useSWR(`user/profile`)
  const loading = !data && !error

  const {
    post,
    isError: postIsError,
    loading: postLoading,
    error: postError
  } = usePost()

  if (loading) {
    return (
      <Card>
        <CardHeader title={<Skeleton height={50} width='100%' />} />
        <CardContent>
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
        </CardContent>
      </Card>
    )
  }

  if (error) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {error}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Snackbar
        isVisible={Boolean(postIsError && postError)}
        type='error'
        message={
          typeof postError === 'string'
            ? postError
            : postError && postError.message
        }
      />

      <Formik
        initialValues={data}
        validationSchema={schema}
        onSubmit={async (values: Object) => {
          const payload = {
            idNumber: values.idNumber,
            name: values.name,
            phoneNumber: values.phoneNumber,
            email: values.email || '',
            userName: values.userName,
            password: values.password || ''
          }

          post('user/profile/update', payload, () => {
            mutate()
            setIsEditable(false)
          })
        }}
      >
        {({
          errors,
          touched,
          resetForm,
          values
        }: {
          errors: Object,
          touched: Object,
          resetForm: Function,
          values: Object
        }) => (
          <Form className={classes.form}>
            <Card>
              <CardHeader
                title='Usuario'
                action={
                  isEditable ? (
                    <Button
                      type='button'
                      fullWidth={false}
                      disabled={postLoading}
                      onClick={() => {
                        resetForm(data)
                        setIsEditable(false)
                      }}
                    >
                      <CloseIcon className={classes.icon} />
                      Cerrar
                    </Button>
                  ) : (
                    <Button
                      type='button'
                      fullWidth={false}
                      onClick={() => setIsEditable(true)}
                    >
                      <EditIcon className={classes.icon} />
                      Editar
                    </Button>
                  )
                }
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='idNumber'
                      as={TextField}
                      label='Número de cédula'
                      readOnly={!isEditable}
                      error={Boolean(touched.idNumber && errors.idNumber)}
                      helperText={touched.idNumber && errors.idNumber}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='name'
                      as={TextField}
                      label='Nombre'
                      readOnly={!isEditable}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='phoneNumber'
                      as={TextField}
                      label='Número de teléfono'
                      readOnly={!isEditable}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='email'
                      as={TextField}
                      label='Correo'
                      type='email'
                      readOnly={!isEditable}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>

                  <Grid item sm={4} xs={12}>
                    <Field
                      name='userName'
                      as={TextField}
                      label='Nombre de usuario'
                      readOnly={!isEditable}
                      error={Boolean(touched.userName && errors.userName)}
                      helperText={touched.userName && errors.userName}
                    />
                  </Grid>

                  {isEditable && (
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='password'
                        as={TextField}
                        label='Contraseña'
                        type={showPassword ? 'text' : 'password'}
                        autoComplete='current-password'
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='Toggle password visibility'
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item sm={4} xs={12}>
                    <Field
                      name='createdAt'
                      as={TextField}
                      label='Fecha de creación'
                      value={formatDate(values.createdAt)}
                      readOnly
                      disabled={isEditable}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              {isEditable && (
                <CardActions>
                  <Button disabled={postLoading} fullWidth={false}>
                    <SaveIcon className={classes.icon} />
                    Guardar
                  </Button>
                </CardActions>
              )}
            </Card>
          </Form>
        )}
      </Formik>
    </>
  )
}

const useStyles = makeStyles({
  form: {
    marginTop: 20,
    width: '100%'
  }
})

export default Profile
