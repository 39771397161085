export default {
  FINISHED: 'FINISHED',
  FIXED: 'FIXED',
  LOWERING: 'LOWERING',
  ADMIN: 'ADMIN',
  SUPERVISOR: 'SUPERVISOR',
  ENUM_DATA: {
    LOAN_STATUSES: {
      ACTIVE: {
        id: 1,
        value: 'Activo'
      },
      INACTIVE: {
        id: 2,
        value: 'Inactivo'
      }
    }
  },
  baseUrl: process.env.REACT_APP_API_URL || 'http://localhost:8086/api',
  ALL: 'ALL',
  REPORT_TYPES: {
    DAILY: 'Diario',
    MONTHLY: 'Mensual'
  }
}
