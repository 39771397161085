import React, { useState } from 'react'
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid
} from '@material-ui/core'
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon
} from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, Field } from 'formik'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { paymentScheme } from 'schemas'
import { Button, TextField, Snackbar, AmountField } from 'components'
import { formatDate, auth } from 'utils'
import { usePost } from 'hooks'

const { initialValues, schema } = paymentScheme.update

function Details() {
  const classes = useStyles()
  const [isEditable, setIsEditable] = useState(false)
  const { id } = useParams()
  const isAdmin = auth.isAdmin()
  const { data, error, mutate } = useSWR(`payment/${id}`)
  const loading = !data && !error
  const {
    post,
    isError: postIsError,
    loading: postLoading,
    error: postError
  } = usePost()

  if (loading) {
    return (
      <Card>
        <CardHeader title={<Skeleton height={50} width='100%' />} />
        <CardContent>
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
        </CardContent>
      </Card>
    )
  }

  if (error) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {error}
        </Typography>
      </Box>
    )
  }

  if (!data) {
    return (
      <Card>
        <CardHeader title={`No existe un pago con el id ${id}`} />
      </Card>
    )
  }

  return (
    <>
      <Snackbar
        isVisible={Boolean(postIsError && postError)}
        type='error'
        message={
          typeof postError === 'string'
            ? postError
            : postError && postError.message
        }
      />

      <Formik
        initialValues={{
          ...initialValues,
          ...data
        }}
        validationSchema={schema}
        onSubmit={async (values: Object) => {
          post('payment/update', values, () => {
            mutate()
            setIsEditable(false)
          })
        }}
      >
        {({
          errors,
          touched,
          setFieldValue,
          resetForm,
          values
        }: {
          errors: Object,
          touched: Object,
          setFieldValue: Function,
          resetForm: Function,
          values: Object
        }) => {
          return (
            <Form>
              <Card>
                <CardHeader
                  title='Pago'
                  action={
                    !isAdmin ? null : isEditable ? (
                      <Button
                        type='button'
                        fullWidth={false}
                        disabled={postLoading}
                        onClick={() => {
                          resetForm({ ...initialValues, ...data })
                          setIsEditable(false)
                        }}
                      >
                        <CloseIcon className={classes.icon} />
                        Cerrar
                      </Button>
                    ) : (
                      <Button
                        type='button'
                        fullWidth={false}
                        onClick={() => setIsEditable(true)}
                      >
                        <EditIcon className={classes.icon} />
                        Editar
                      </Button>
                    )
                  }
                />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='id'
                        as={TextField}
                        label='Id'
                        readOnly
                        disabled={isEditable}
                        error={Boolean(touched.id && errors.id)}
                        helperText={touched.id && errors.id}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='user'
                        as={TextField}
                        label='Cobrador'
                        value={values.user.name}
                        readOnly
                        disabled={isEditable}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='client'
                        as={TextField}
                        label='Cliente'
                        value={values.client.name}
                        readOnly
                        disabled={isEditable}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='phoneNumber'
                        as={TextField}
                        label='Numero de telefono'
                        value={values.client.phoneNumber}
                        readOnly
                        disabled={isEditable}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field name='amount'>
                        {({ field: { name, value } }: { field: Object }) => (
                          <AmountField
                            name={name}
                            label='Monto*'
                            value={value}
                            readOnly={!isEditable}
                            onValueChange={(values: Object) => {
                              setFieldValue(name, values.value)
                            }}
                            error={Boolean(touched.amount && errors.amount)}
                            helperText={touched.amount && errors.amount}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field name='interestAmount'>
                        {({ field: { name, value } }: { field: Object }) => (
                          <AmountField
                            name={name}
                            label='Monto al interes'
                            value={value}
                            readOnly={!isEditable}
                            onValueChange={(values: Object) => {
                              setFieldValue(name, values.value)
                            }}
                            error={Boolean(
                              touched.interestAmount && errors.interestAmount
                            )}
                            helperText={
                              touched.interestAmount && errors.interestAmount
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field name='capitalAmount'>
                        {({ field: { name, value } }: { field: Object }) => (
                          <AmountField
                            name={name}
                            label='Monto al capital'
                            value={value}
                            readOnly={!isEditable}
                            onValueChange={(values: Object) => {
                              setFieldValue(name, values.value)
                            }}
                            error={Boolean(
                              touched.capitalAmount && errors.capitalAmount
                            )}
                            helperText={
                              touched.capitalAmount && errors.capitalAmount
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field name='penaltyAmount'>
                        {({ field: { name, value } }: { field: Object }) => (
                          <AmountField
                            name={name}
                            label='Monto a deudas'
                            value={value}
                            readOnly={!isEditable}
                            onValueChange={(values: Object) => {
                              setFieldValue(name, values.value)
                            }}
                            error={Boolean(
                              touched.penaltyAmount && errors.penaltyAmount
                            )}
                            helperText={
                              touched.penaltyAmount && errors.penaltyAmount
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field name='currentBalanceAmount'>
                        {({ field: { name, value } }: { field: Object }) => (
                          <AmountField
                            name={name}
                            label='Balance'
                            value={value}
                            readOnly={!isEditable}
                            onValueChange={(values: Object) => {
                              setFieldValue(name, values.value)
                            }}
                            error={Boolean(
                              touched.currentBalanceAmount &&
                                errors.currentBalanceAmount
                            )}
                            helperText={
                              touched.currentBalanceAmount &&
                              errors.currentBalanceAmount
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='notes'
                        as={TextField}
                        label='Notas'
                        readOnly={!isEditable}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='payday'
                        as={TextField}
                        label='Fecha del pago'
                        readOnly
                        disabled={isEditable}
                        value={formatDate(values.payday)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                {isAdmin && isEditable && (
                  <CardActions>
                    <Button disabled={postLoading} fullWidth={false}>
                      <SaveIcon className={classes.icon} />
                      Guardar
                    </Button>
                  </CardActions>
                )}
              </Card>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const useStyles = makeStyles((theme: Object) => ({
  icon: {
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row'
  }
}))

export default Details
