// @flow
import React, { useState } from 'react'
import {
  Typography,
  Box,
  MenuItem,
  CircularProgress,
  InputAdornment,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid
} from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Formik, Form, Field } from 'formik'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import useSWR from 'swr'

import { TextField, Button, Snackbar, FileUpload } from 'components'
import { userScheme } from 'schemas'
import { usePost, useFileUpload } from 'hooks'

const { initialValues, schema } = userScheme.create

function AddUsers() {
  const classes = useStyles()
  const history = useHistory()
  const { data, error: userError } = useSWR('user/props')
  const userLoading = !data && !userError
  const { post, isError, isSucceded, loading, error } = usePost()
  const {
    upload: uploadFront,
    loading: uploadFrontLoading,
    error: uploadFrontError
  } = useFileUpload()
  const {
    upload: uploadBack,
    loading: uploadBackLoading,
    error: uploadBackError
  } = useFileUpload()
  const [showPassword, setShowPassword] = useState(false)

  if (userError) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {userError}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Snackbar
        isVisible={Boolean(isError && error)}
        type='error'
        message={typeof error === 'string' ? error : error && error.message}
      />

      <Snackbar
        isVisible={isSucceded}
        message='Usuario agregado correctamente :)'
      />

      <Snackbar
        isVisible={Boolean(uploadFrontError)}
        type='error'
        message={
          typeof uploadFrontError === 'string'
            ? uploadFrontError
            : uploadFrontError && uploadFrontError.message
        }
      />

      <Snackbar
        isVisible={Boolean(uploadBackError)}
        type='error'
        message={
          typeof uploadBackError === 'string'
            ? uploadBackError
            : uploadBackError && uploadBackError.message
        }
      />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values: Object) =>
          post('user/create', values, (user: Object) => {
            history.push(`/inicio/usuarios/perfil/${user.id}`, user)
          })
        }
      >
        {({ errors, touched }: { errors: Object, touched: Object }) => (
          <Form>
            <Card>
              <CardHeader title='Usuario' />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='name'
                      as={TextField}
                      label='Nombre completo*'
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='idNumber'
                      as={TextField}
                      label='Número de cédula *'
                      error={Boolean(touched.idNumber && errors.idNumber)}
                      helperText={touched.idNumber && errors.idNumber}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='phoneNumber'
                      as={TextField}
                      label='Numero de telefono *'
                      type='tel'
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='email'
                      as={TextField}
                      label='Correo'
                      type='email'
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='userName'
                      as={TextField}
                      label='Nombre de usuario *'
                      error={Boolean(touched.userName && errors.userName)}
                      helperText={touched.userName && errors.userName}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='password'
                      as={TextField}
                      label='Contraseña*'
                      type={showPassword ? 'text' : 'password'}
                      autoComplete='current-password'
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='Toggle password visibility'
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field name='locationIds'>
                      {({ field }: { field: Object }) => {
                        if (userLoading) {
                          return (
                            <Box display='flex' justifyContent='center'>
                              <CircularProgress />
                            </Box>
                          )
                        }

                        return (
                          <TextField
                            select
                            label='Ubicaciones'
                            error={
                              !!(touched.locationIds && errors.locationIds)
                            }
                            helperText={
                              touched.locationIds && errors.locationIds
                            }
                            SelectProps={{
                              multiple: true
                            }}
                            {...field}
                          >
                            {data?.locations?.map((location: Object) => {
                              return (
                                <MenuItem
                                  key={location?.id}
                                  value={location?.id}
                                >
                                  {location?.name}
                                </MenuItem>
                              )
                            })}
                          </TextField>
                        )
                      }}
                    </Field>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field name='userTypeId'>
                      {({ field }: { field: Object }) => {
                        if (userLoading) {
                          return (
                            <Box display='flex' justifyContent='center'>
                              <CircularProgress />
                            </Box>
                          )
                        }

                        return (
                          <TextField
                            select
                            {...field}
                            label='Tipo*'
                            error={Boolean(
                              touched.userTypeId && errors.userTypeId
                            )}
                            helperText={touched.userTypeId && errors.userTypeId}
                          >
                            {data?.userTypes.map(
                              ({ id, name }: { id: number, name: string }) => (
                                <MenuItem key={id} value={id}>
                                  {name}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        )
                      }}
                    </Field>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field name='userStatusId'>
                      {({ field }: { field: Object }) => {
                        if (userLoading) {
                          return (
                            <Box display='flex' justifyContent='center'>
                              <CircularProgress />
                            </Box>
                          )
                        }

                        return (
                          <TextField
                            select
                            {...field}
                            label='Estato*'
                            error={Boolean(
                              touched.userStatusId && errors.userStatusId
                            )}
                            helperText={
                              touched.userStatusId && errors.userStatusId
                            }
                          >
                            {data?.userStatuses.map(
                              ({ id, name }: { id: number, name: string }) => (
                                <MenuItem key={id} value={id}>
                                  {name}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        )
                      }}
                    </Field>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Field name='idFrontPhoto'>
                      {({
                        field: { name, value },
                        form: { setFieldValue }
                      }: {
                        field: Object,
                        form: Object
                      }) => {
                        return (
                          <FileUpload
                            name={name}
                            label='Foto del frente de la cédula'
                            loading={uploadFrontLoading}
                            value={value}
                            onChange={async ({
                              target: {
                                validity,
                                files: [file]
                              }
                            }: {
                              target: Object
                            }) => {
                              try {
                                if (validity.valid && file) {
                                  setFieldValue('idFrontPhoto', '')

                                  const { name, type } = file
                                  const newName = `${Date.now().toString()}${name}`

                                  const fileCopy = new File([file], newName, {
                                    type
                                  })

                                  await uploadFront({
                                    name: fileCopy.name,
                                    type: fileCopy.type,
                                    file: fileCopy,
                                    throwError: true
                                  })

                                  setFieldValue('idFrontPhoto', newName)
                                }
                              } catch (error) {
                                setFieldValue('idFrontPhoto', '')
                              }
                            }}
                          />
                        )
                      }}
                    </Field>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Field name='idBackPhoto'>
                      {({
                        field: { name, value },
                        form: { setFieldValue }
                      }: {
                        field: Object,
                        form: Object
                      }) => {
                        return (
                          <FileUpload
                            name={name}
                            label='Foto de atrás de la cédula'
                            loading={uploadBackLoading}
                            value={value}
                            onChange={async ({
                              target: {
                                validity,
                                files: [file]
                              }
                            }: {
                              target: Object
                            }) => {
                              try {
                                if (validity.valid && file) {
                                  setFieldValue('idBackPhoto', '')

                                  const { name, type } = file
                                  const newName = `${Date.now().toString()}${name}`

                                  const fileCopy = new File([file], newName, {
                                    type
                                  })

                                  await uploadBack({
                                    name: fileCopy.name,
                                    type: fileCopy.type,
                                    file: fileCopy,
                                    throwError: true
                                  })

                                  setFieldValue('idBackPhoto', newName)
                                }
                              } catch (error) {
                                setFieldValue('idBackPhoto', '')
                              }
                            }}
                          />
                        )
                      }}
                    </Field>
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <Button disabled={loading} fullWidth={false}>
                  <SaveIcon className={classes.icon} />
                  Agregar
                </Button>
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  )
}

const useStyles = makeStyles((theme: Object) => ({
  icon: {
    marginRight: theme.spacing(1)
  }
}))

export default AddUsers
