// @flow
import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid
} from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'
import { Formik, Form, Field } from 'formik'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { TextField, Button, Snackbar, Map } from 'components'
import { locationScheme } from 'schemas'
import { usePost } from 'hooks'
import { mapConfig } from 'config'

const { initialValues, schema } = locationScheme

function AddLocation() {
  const classes = useStyles()
  const history = useHistory()
  const { post, isError, loading, error } = usePost()
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(false)
  const [geolocation, setGeolocation] = useState(null)
  const geolocationLocation = navigator?.geolocation
  const geography = geolocation
    ? {
        type: 'Point',
        coordinates: [geolocation.lng, geolocation.lat]
      }
    : null

  const onMapClick = (value: Object) => {
    setGeolocation({
      lat: value.lat,
      lng: value.lng
    })
  }

  const handleShowCurrentLocation = () => {
    if (geolocationLocation) {
      setLoadingCurrentLocation(true)
      geolocationLocation.getCurrentPosition((position: Object) => {
        const { latitude: lat, longitude: lng } = position.coords
        setGeolocation({
          lat,
          lng
        })
        setLoadingCurrentLocation(false)
      })
    }
  }

  return (
    <>
      <Snackbar
        isVisible={Boolean(isError && error)}
        type='error'
        message={typeof error === 'string' ? error : error && error.message}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values: Object) =>
          post(
            'location/create',
            {
              ...values,
              geolocation: geography
            },
            (location: Object) => {
              history.push(
                `/inicio/ubicaciones/detalle/${location.id}`,
                location
              )
            }
          )
        }
      >
        {({ errors, touched }: { errors: Object, touched: Object }) => (
          <Form>
            <Card>
              <CardHeader title='Ubicación' />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='name'
                      as={TextField}
                      label='Nombre *'
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>

                  <Grid item sm={4} xs={12}>
                    <Field
                      name='idNumber'
                      as={TextField}
                      label='Número de cédula'
                      error={Boolean(touched.idNumber && errors.idNumber)}
                      helperText={touched.idNumber && errors.idNumber}
                    />
                  </Grid>

                  <Grid item sm={4} xs={12}>
                    <Field
                      name='phoneNumber'
                      as={TextField}
                      label='Número de teléfono *'
                      type='tel'
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Grid>

                  <Grid item sm={4} xs={12}>
                    <Field
                      name='email'
                      as={TextField}
                      type='email'
                      label='Correo'
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='description'
                      as={TextField}
                      label='Descripción'
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Map
                      loadingCurrentLocation={loadingCurrentLocation}
                      defaultCenter={mapConfig.center}
                      onClick={onMapClick}
                      center={geolocation}
                      showCurrentLocation={handleShowCurrentLocation}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <Button disabled={loading} fullWidth={false}>
                  <SaveIcon className={classes.icon} />
                  Agregar
                </Button>
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  )
}

const useStyles = makeStyles((theme: Object) => ({
  icon: {
    marginRight: theme.spacing(1)
  }
}))

export default AddLocation
