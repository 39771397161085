// @flow
import React, { useState } from 'react'
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  MenuItem
} from '@material-ui/core'
import { MTableToolbar } from 'material-table'
import useSWR from 'swr'

import { useMatchSizes } from 'hooks'
import { Table, TextField, Link, Paper } from 'components'
import { formatAmount, formatDate } from 'utils'
import { constants } from 'config'

const getUrls = (id: number) => {
  if (!id || id === 'all') {
    return 'payment/createdToday'
  }
  return `payment/createdToday/${id}`
}

function ListPayments() {
  const [id, setId] = useState('all')
  const url = getUrls(id)
  const { data, error } = useSWR(url)
  const loading = !data && !error
  const { data: usersData, error: errorUsers } = useSWR('user/allBase')
  const loadingUsers = !usersData && !error
  const haveError = error || errorUsers
  const { xsDown } = useMatchSizes()

  if (haveError) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {haveError}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Typography variant='h6'>Pagos de hoy</Typography>

      <TextField
        select
        label='Usuario*'
        value={id}
        onChange={(ev: Object) => setId(ev.target.value)}
      >
        {!loadingUsers &&
          usersData &&
          usersData.map(({ id, name }: { id: number, name: string }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        <MenuItem key={'all'} value={'all'}>
          Todos
        </MenuItem>
      </TextField>

      <Grid container justify='center' spacing={2}>
        <Grid item xs={12}>
          {loading && (
            <Box display='flex' justifyContent='center'>
              <CircularProgress />
            </Box>
          )}

          {!loading && data && data.payments && (
            <Table
              name='pagos'
              components={{
                Toolbar: function Toolbar(props: Object) {
                  const totalAmount =
                    (props &&
                      props.data &&
                      props.data.reduce(
                        (sum: number, item: Object) => sum + item.amount,
                        0
                      )) ||
                    0

                  const capitalAmount =
                    (props &&
                      props.data &&
                      props.data.reduce(
                        (sum: number, item: Object) => sum + item.capitalAmount,
                        0
                      )) ||
                    0

                  const interestAmount =
                    (props &&
                      props.data &&
                      props.data.reduce(
                        (sum: number, item: Object) =>
                          sum + item.interestAmount + item.penaltyAmount,
                        0
                      )) ||
                    0

                  return (
                    <>
                      <MTableToolbar {...props} />
                      <Box display='flex'>
                        <Paper>
                          <Typography variant='body2'>{`Total: ${formatAmount(
                            totalAmount
                          )}`}</Typography>
                        </Paper>

                        <Paper>
                          <Typography variant='body2'>{`Capital: ${formatAmount(
                            capitalAmount
                          )}`}</Typography>
                        </Paper>

                        <Paper>
                          <Typography variant='body2'>{`Interes: ${formatAmount(
                            interestAmount
                          )}`}</Typography>
                        </Paper>
                      </Box>
                    </>
                  )
                }
              }}
              columns={[
                {
                  title: 'Id',
                  field: 'id',
                  render: function Column(rowData: Object) {
                    return (
                      <Link
                        to={{
                          pathname: `/inicio/pagos/detalle/${rowData.id}`,
                          state: rowData
                        }}
                      >
                        <Typography variant='body2' color='primary'>
                          {rowData.id}
                        </Typography>
                      </Link>
                    )
                  }
                },
                {
                  title: 'Préstamo',
                  field: 'loan.id',
                  render: function Column(rowData: Object) {
                    return (
                      <Link
                        to={{
                          pathname: `/inicio/prestamos/detalle/${rowData.loan.id}`
                        }}
                      >
                        <Typography variant='body2' color='primary'>
                          {rowData.loan.id}
                        </Typography>
                      </Link>
                    )
                  }
                },
                {
                  title: 'Cliente',
                  field: 'client.name',
                  render: function ClientNameRow(rowData: Object) {
                    return (
                      <Link to={`/inicio/clientes/perfil/${rowData.clientId}`}>
                        <Typography variant='body2' color='primary'>
                          {rowData.client.name}
                        </Typography>
                      </Link>
                    )
                  }
                },
                {
                  title: 'Ubicación',
                  field: 'location.name',
                  render: function LocationNameRow(rowData: Object) {
                    return (
                      <Link to={`/inicio/ubicaciones/${rowData?.location?.id}`}>
                        <Typography variant='body2' color='primary'>
                          {rowData?.location?.name}
                        </Typography>
                      </Link>
                    )
                  }
                },
                {
                  title: 'Cobrador',
                  field: 'user.name',
                  hidden: xsDown,
                  render: function UserNameRow(rowData: Object) {
                    return (
                      <Link to={`/inicio/usuarios/perfil/${rowData.userId}`}>
                        <Typography variant='body2' color='primary'>
                          {rowData.user.name}
                        </Typography>
                      </Link>
                    )
                  }
                },
                {
                  title: 'Tipo',
                  field: 'loan.loanType.name',
                  hidden: xsDown
                },
                {
                  title: 'Interes %',
                  field: 'loan.interestPercentage',
                  customFilterAndSearch: (term: string, rowData: Object) => {
                    return (
                      rowData.loan.interestPercentage.toString() ===
                      term.toString()
                    )
                  },
                  hidden: xsDown,
                  render: function InterestPercentageRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {`${rowData.loan.interestPercentage}%`}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Capital %',
                  field: 'loan.capitalPercentage',
                  customFilterAndSearch: (term: string, rowData: Object) => {
                    return (
                      rowData.loan.capitalPercentage.toString() ===
                      term.toString()
                    )
                  },
                  hidden: xsDown,
                  render: function CapitalPercentageRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {`${rowData.loan.capitalPercentage}%`}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Periodicidad',
                  field: 'loan.loanPeriodicity.name',
                  hidden: xsDown
                },
                {
                  title: 'Interes',
                  field: 'interestAmount',
                  render: function InterestAmountRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.interestAmount)}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Capital',
                  field: 'capitalAmount',
                  render: function CapitalAmountRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.capitalAmount)}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Penalización',
                  field: 'penaltyAmount',
                  render: function PenaltyAmountRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.penaltyAmount)}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Monto',
                  field: 'amount',
                  render: function AmountRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.amount)}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Fecha',
                  field: 'createdAt',
                  render: function DateRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatDate(rowData.createdAt)}
                      </Typography>
                    )
                  },
                  hidden: xsDown
                },
                {
                  title: 'Balance',
                  field: 'currentBalanceAmount',
                  render: function DateRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.currentBalanceAmount)}
                      </Typography>
                    )
                  },
                  hidden: xsDown
                }
              ]}
              data={data.payments}
              actions={[
                {
                  icon: 'receipt',
                  tooltip: 'Ver recibo',
                  onClick: (event: Object, rowData: Object) => {
                    window.open(
                      `${constants.baseUrl}/docs/paymentReceipt/${rowData?.client?.id}/${rowData?.id}`
                    )
                  }
                }
              ]}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ListPayments
