// @flow
import * as yup from 'yup'

import { regex } from 'utils'

const initialValues = {
  name: '',
  idNumber: '',
  phoneNumber: '',
  email: '',
  description: ''
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre es obligatorio.'),
  idNumber: yup.string(),
  phoneNumber: yup
    .string()
    .matches(regex.onlyNumbers, 'El teléfono solo debe contener numeros.')
    .min(8, 'El mínimo de numeros para el teléfono son 8.'),
  email: yup.string(),
  description: yup.string()
})

export default {
  initialValues,
  schema
}
