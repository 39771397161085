import { CsvBuilder } from 'filefy'
import get from 'lodash.get'
import moment from 'moment'

import { constants } from 'config'

const exportCsv = (
  columns: Array<Object>,
  data: Array<Object>,
  name: string
) => {
  const builder = new CsvBuilder(
    `${name}-${moment().format('DD-MM-YYYY-hhmma')}`
  )
  const exportColumns = columns.map((columnDef: Object) => columnDef.title)

  const exportData = data.map((rowData: Object) => {
    return columns.map((columnDef: Object) => {
      switch (columnDef.field) {
        case 'createdAt':
        case 'payday':
          return moment(get(rowData, columnDef.field, '')).format('DD-MM-YYYY')

        case 'daysLate': {
          const paydate = moment(get(rowData, 'payday', ''))
          const daysLate = moment().diff(paydate, 'days')
          const isFinished =
            get(rowData, 'loanStatus.status', '') === constants.FINISHED

          const value = isFinished || daysLate < 0 ? 0 : daysLate

          return value
        }

        default:
          return get(rowData, columnDef.field, '')
      }
    })
  })

  builder.setColumns(exportColumns).addRows(exportData).exportFile()
}

export default {
  exportCsv
}
