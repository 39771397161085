// @flow
import React from 'react'
import { Typography, Box, Link as MuiLink } from '@material-ui/core'
import useSWR from 'swr'

import { useMatchSizes } from 'hooks'
import { Table, Link } from 'components'

function ListLocations() {
  const { data, error } = useSWR('location/all')
  const loading = !data && !error
  const { xsDown } = useMatchSizes()

  if (error) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {error}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Typography align='center' variant='h6'>
        Lista de ubicaciones
      </Typography>

      <Table
        name='ubicaciones'
        isLoading={loading}
        data={data || []}
        columns={[
          {
            title: 'Id',
            field: 'id',
            render: function Column(rowData: Object) {
              return (
                <Link
                  to={{
                    pathname: `/inicio/ubicaciones/detalle/${rowData.id}`
                  }}
                >
                  <Typography variant='body2' color='primary'>
                    {rowData.id}
                  </Typography>
                </Link>
              )
            }
          },
          {
            title: 'Nombre',
            field: 'name'
          },
          {
            title: 'Cédula',
            field: 'idNumber',
            hidden: xsDown
          },
          {
            title: 'Teléfono',
            field: 'phoneNumber',
            disableClick: true,
            render: function PhoneNumberRow(rowData: Object) {
              return (
                <MuiLink
                  target='_blank'
                  rel='noreferrer'
                  href={`https://wa.me/506${rowData.phoneNumber}`}
                >
                  <Typography variant='body2' color='primary'>
                    {rowData.phoneNumber}
                  </Typography>
                </MuiLink>
              )
            }
          },
          {
            title: 'Correo',
            field: 'email',
            hidden: true
          },
          {
            title: 'Descripción',
            field: 'description',
            hidden: true
          }
        ]}
      />
    </>
  )
}

export default ListLocations
